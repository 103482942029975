<template>
  <v-container style="margin-top: 1em">
    <v-row>
      <span>{{ $t("date_picker_title") }}&nbsp;</span>
      <h4 style="place-self: center">
        {{ new Date(date.id).getDate() }}.
        {{ new Date(date.id).getMonth() + 1 }}.
        {{ new Date(date.id).getFullYear() }}
      </h4></v-row
    >
    <v-select
      v-model="select"
      :items="repeatModes"
      item-text="title"
      item-value="value"
      return-object
      style="width: 100%"
      @input="close_dialog"
    ></v-select>
  </v-container>
</template>

<script>
export default {
  props: ["date"],
  data() {
    return {
      selected: null,
      repeatModes: [
        { title: this.$t("repeats")[0], value: "daily" },
        { title: this.$t("repeats")[1], value: "weekly" },
        { title: this.$t("repeats")[2], value: "monthly" },
      ],
    };
  },
  computed: {
    select: {
      get() {
        switch (this.date.repeat) {
          case "daily":
            this.selected = this.repeatModes[0];
            return this.selected;

          case "weekly":
            this.selected = this.repeatModes[1];
            return this.selected;

          case "monthly":
            this.selected = this.repeatModes[2];
            return this.selected;

          default:
            this.selected = this.repeatModes[0];
            return this.selected;
        }
      },
      set(val) {
        this.selected = val;
      },
    },
  },
  methods: {
    close_dialog() {
      this.$emit("close-dialog", { selected: this.selected });
    },
  },
};
</script>
